.iterative-process-container img {
  height: 130px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.middle-img-container {
  position: absolute;
  top: 50%;
  /* Move the top of the item to the vertical center of the parent */
  left: 50%;
  /* Move the left of the item to the horizontal center of the parent */
  transform: translate(-50%, -50%);
}

.cycle-text {
  font-size: 2rem;
}

.octi-img {
  width: 100%;
  height: 100%;
}

.arrow-img-mobile {
  width: 150px;
  height: 150px;
}

.arrow-img {
  width: 60%;
  height: 60%;
}

.icon-container {
  opacity: 1;
  transition: all 0.5s;
  -webkit-animation: spin-right 10s linear infinite;
  animation: spin-right 10s linear infinite;
}

.iterative-process-container {
  min-height: 900px;
  color: white;
  font-family: 'Inter';
}

.cycle-mid {
  position: relative;
  width: 100%;
  height: 300px;
}

.inner-mid-cycle {
  display: flex;
  justify-content: space-between;
  margin: 0px 8%;
  height: 300px;
}

.middle-img {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo-mid {
  position: absolute;
  height: 30%;
  width: 30%;
}

.logo-mid-mobile {
  position: absolute;
}
.point-1-container {
  position: relative;
  text-align: center;
  margin-right: 10px;
  width: 42px;
  height: 42px;
  border-radius: 5px;
  background: linear-gradient(139deg, #e015a2 7.38%, #624ba2 100.8%);
}

.text-1 {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
}

.point-text {
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}
.point-2-container {
  width: 42px;
  height: 42px;
  border-radius: 10px;
  background: linear-gradient(138.89deg, #e015a2 7.38%, #624ba2 87.8%);
}

.text-2 {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}

.point-3-container {
  width: 42px;
  height: 42px;
  border-radius: 10px;
  background: linear-gradient(138.89deg, #e015a2 7.38%, #624ba2 87.8%);
}

.text-3 {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}

.point-4-container {
  width: 42px;
  height: 42px;
  border-radius: 10px;
  background: linear-gradient(138.89deg, #e015a2 7.38%, #624ba2 87.8%);
}

.text-4 {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
}

.first-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 350px;
}

.first-container1 {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.cycle-points-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  position: relative;
}

.cycle-points-items {
  display: flex;
  align-items: center;
  position: relative;
}

.cycle-mid-mobile {
  display: none;
}

.cycle-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 5% 0px;
}

.mid-cycle-text {
  position: absolute;
}

.cycle-points-container {
  display: none;
  flex-direction: column;
  gap: 15px;
}

.text-point {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
}

@keyframes spin-right {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (max-width: 1200px) {
  .text-1 {
    font-size: 20px;
  }

  .text-point {
    font-size: 20px;
  }
}

@media (max-width: 992px) {
  .text-1 {
    font-size: 16px;
  }
  .text-point {
    font-size: 16px;
  }
}

@media (max-width: 576px) {
  .text-1 {
    font-size: 10px;
    display: none;
  }
  .text-point {
    font-size: 10px;
  }
  .point-text {
    font-size: 10px;
  }
  .cycle-points-container {
    display: flex;
  }

  .cycle-container {
    height: auto;
  }
  .middle-img-mobile {
    position: relative;
    margin-right: 5px;
  }
  .cycle-mid {
    display: none;
  }
  .cycle-mid-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo-mid {
    position: absolute;
    height: 90px;
    width: 90px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .first-container1 {
    margin: 5px;
  }
}

@media (max-width: 768px) {
  .text-1 {
    font-size: 10px;
    display: none;
  }
  .text-point {
    font-size: 10px;
  }
  .point-text {
    font-size: 10px;
  }
  .cycle-points-container {
    display: flex;
  }

  .cycle-container {
    padding: 0px;
    height: auto;
  }
  .middle-img-mobile {
    position: relative;
    margin-right: 5px;
  }
  .cycle-mid {
    display: none;
  }
  .cycle-mid-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logo-mid {
    position: absolute;
    height: 90px;
    width: 90px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .first-container1 {
    margin: 5px;
    display: none;
  }
}
@media (max-width: 1200px) {
  .logo-mid {
    height: 30%;
    width: 30%;
  }
}
@media (max-width: 1050px) {
  .arrow-img {
    width: 40%;
    height: 40%;
  }
}
@media (max-width: 900px) {
  .first-container {
    width: 250px;
  }
}
