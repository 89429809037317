@font-face {
  font-family: satoshi-Bold;
  src: url(../../../satoshi-cufonfonts/Satoshi-Bold.otf);
}

@font-face {
  font-family: satoshi-Light;
  src: url(../../../satoshi-cufonfonts/Satoshi-Light.otf);
}

@font-face {
  font-family: satoshi;
  src: url(../../../satoshi-cufonfonts/Satoshi-Regular.otf);
}

.contentBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.contentbox-title {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: center;
  font-family: satoshi-Bold !important;
}

.margin-left {
  margin-left: 10%;
}

.full-width {
  width: 100%;
}

.pink-section {
  margin-top: 213px;
}

/* .join-us-contentBox{

  .join-us-firstTitle{
    color: white !important;
    font-weight: 700 !important;
    font-size: 20px !important;
  }
} 

.join-us-contentBox{
  
  .join-us-secondTitle{
    color:white !important;
    padding-top: 15px !important;
    font-size: 18px !important;
    font-weight: 300;
  }
} 

.join-us-contentBox {

  .join-us-subSecondTitle{
    color: white !important;
    font-size: 18px !important;
    font-weight: 300;
  }

} */

/* .contentbox-title-text p{
  margin-top: 0;
  margin-bottom:0rem ;
} */
.Continuous-Evolution-design {
  background: linear-gradient(173deg, #e015a2 18.6%, #6a56a5 67.44%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.contentbox-title-text {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 65px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
  margin-top: 57px;
}

.contentbox-title-text-gradient {
  background: linear-gradient(173deg, #e015a2 18.6%, #6a56a5 67.44%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: satoshi-Bold !important;
  font-weight: 700;
}

.contentbox-subtitle {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.contentbox-subtitle-text {
  /* color: #b8bbca; */
  text-align: center;
  font-family: Satoshi;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 10px;
}

@media (max-width: 1400px) {
  .contentbox-title-text {
    font-size: 65px;
    font-weight: 800;
    /* margin-top: 127px; */
  }
  .contentbox-subtitle-text {
    font-size: 24px;
    font-weight: 500;
  }
  .pink-section {
    margin-top: 169px;
  }
}
@media (max-width: 1200px) {
  .contentbox-title-text {
    font-size: 58px;
    font-weight: 700;
    /* margin-top: 116px; */
  }

  .contentbox-subtitle-text {
    font-size: 18px;
    font-weight: 500;
  }
}

@media (max-width: 576px) {
  .contentbox-title-text {
    margin-top: 63px;
  }
  .Continuous-Evolution-design {
    margin: -30px;
  }

  .contentbox-subtitle-text {
    margin: 20px;
    font-size: 16px;
    line-height: normal;
    margin-top: -22px;
  }
  .pink-section {
    margin-top: 112px;
  }
}
