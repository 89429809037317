@font-face {
  font-family: satoshi-Bold;
  src: url(../../../satoshi-cufonfonts/Satoshi-Bold.otf);
}

@font-face {
  font-family: satoshi-Light;
  src: url(../../../satoshi-cufonfonts/Satoshi-Light.otf);
}

@font-face {
  font-family: satoshi;
  src: url(../../../satoshi-cufonfonts/Satoshi-Regular.otf);
}
.footer_container {
  margin: 0 96px;
  /* background-color: #E015A2 !important; */
  color: white !important;
  font-family: satoshi !important;
  font-family: 'Inter';
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-content {
  display: flex;
  justify-content: space-between;
  width: 30%;
}

.footer-content h1 {
  font-size: 25px;
  font-weight: 700;
  font-family: 'Inter';
  padding: 0;
  text-align: start;
  margin-bottom: 30px;
}

.footer-content ul {
  padding: 0;
}

.footer-content li {
  list-style: none;
  font-size: 20px;
  font-weight: 400;
}

.copyright-section {
  background: linear-gradient(90deg, #a8117a 0%, #534085 100%);
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.copyright-section h1 {
  font-size: 20px;
  font-family: 'Inter';
  font-weight: 500;
  color: white;
  padding: 0;
  margin: 0;
}

.icons {
  max-width: 250px;
}

.footer_container ul li {
  margin-top: 10px;
}

.footer_container .row > div:nth-child(4) div:nth-child(1) {
  background-color: #120121;
  border-radius: 23px;
}

.footer-text {
  color: #e0dadad7 !important;
  cursor: pointer;
}

.footer-text:hover {
  color: white !important;
  filter: brightness(1.1) !important;
}

.list-unstyled {
  cursor: pointer;
}

.footer-details-main {
  display: flex;
  justify-content: space-around;
  gap: 150px;
}

.footer-logo {
  position: relative;
  display: inline-block;
  padding-right: 106px;
}

.inside-img {
  position: relative;
}

.beta {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.text-footer {
  top: 0px;
  padding: 0px 19px;
  font-size: 12px;
  font-family: inter;
}

.horizontal-line {
  margin: 0 96px;
  border-top: 1px solid #e015a2;
}

.beta-link {
  text-decoration: none;
  color: white;
}

.beta-link {
  width: 100% !important;
}

.parent-beta {
  width: 337px;
}

.child-beta {
  /* background-color: rebeccapurple; */
  width: 100%;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.footer-para {
  color: #b8bbca;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.octi-learn-img {
  height: 102.101px;
}

.beta-container {
  position: relative;
}

.experience-text {
  font-size: 20px;
  font-family: Inter;
}

.social-media-icon {
  gap: 30px;
}

.border-div {
  display: flex;

  background-color: rgba(98, 75, 162, 0.2);
  border-radius: 30px;
  padding: 10px;
  align-items: center;
  position: relative;
}

.apply-text {
  margin: 0px;
  font-size: 20px;
  font-family: Inter;
  font-weight: 400;
}

.footer-main-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 136px;
}

.footer-list-header {
  color: #fff;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.footer-list-headings {
  margin: 40px 0;
  color: #b8bbca;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

@media (max-width: 1400px) {
  .horizontal-line {
    margin: 0 61px;
  }

  .footer_container {
    margin: 0 61px;
  }
}

@media (max-width: 1200px) {
  .horizontal-line {
    margin: 0 51px;
  }

  .footer_container {
    margin: 0 51px;
  }

  .footer-main-container {
    gap: 65px;
  }
}

@media (max-width: 992px) {
  .footer_container {
    margin: 0 39px;
  }

  /* .footer-main-container {
    display: block;
  }

  .border-div {
    margin-top: 25px;
    width: 266px;
  } */
}

@media (max-width: 576px) {
  .horizontal-line {
    margin: 0 29px;
  }

  .footer-content {
    width: 100%;
    justify-content: space-between;
  }

  .footer_container {
    margin: 0 29px;
  }

  .apply-text {
    font-size: 12px;
  }

  .footer-logo {
    display: block;
  }

  /* .octi-learn-img {
    padding-left: 23px;
  } */
  /* 
  .experience-text {
    padding-left: 20px;
  } */

  .social-media-icon {
    /* padding-left: 20px; */
    gap: 18px;
  }

  .border-div {
    margin-top: 25px;
  }

  .footer-main-container {
    align-items: center;
  }
}

@media (max-width: 430px) {
  .horizontal-line {
    margin: 0 19px;
  }

  .footer_container {
    margin: 0 19px;
  }

  .footer-main-container {
    flex-direction: column;
    align-items: flex-start;
  }

  .footer-list-header {
    font-size: 16px;
  }

  .footer-list-headings {
    font-size: 12px;
  }

  .parent-beta {
    width: 100%;
  }
}
