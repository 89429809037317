.nav_container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 120;
}

.navigation_menu ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #e0dadad7;
  list-style: none;
  cursor: pointer;
}

.navigation_menu ul li {
  display: flex;
  list-style: none;
  margin-left: 40px;
  font-family: 'Inter';
  transition: all 0.5s;
  cursor: pointer;
  font-size: 18px;
}

.navigation_menu ul li:hover {
  /* color: #6a56a5; */
  color: white;
}

.nav_item {
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: end;
}

.nav_icon {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 4px;
  width: 100%;
}

.nav_circle {
  background: #b2ebf2;
  width: 6px;
  height: 6px;
  border-radius: 100%;
}

.nav_bar {
  width: 30%;
  height: 6px;
  border-radius: 4px;
  background-color: white;
}

.header-home {
  color: white;
  font-weight: 700;
}

.benefit-button {
  background-color: #6a56a5 !important;
  color: white;
  border: none;
}

.nav_buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  font-weight: 600;
  font-size: 16px;
  font-style: normal;
  font-family: 'Inter';
}

.gradient_button {
  background: linear-gradient(90.06deg, #e015a2 -10.53%, #2f234f 100.54%);
  color: white;
  padding: 14px 0px;
  border-radius: 50px;
  min-width: 150px !important;
  padding: 14px 30px;
  border: none;
  outline: none;
}

.gradient_button:hover {
  background: linear-gradient(90.06deg, #2f234f -8.45%, #e015a2 101.47%);
  transition: 300ms;
}

.stroke-btn {
  background: transparent;
  color: white;
  border: 2px solid #61469b;
  padding: 14px 62px;
  border-radius: 50px;
  min-width: 150px;
  max-width: 150px;
  padding: 14px 30px;
}

.stroke-btn:hover {
  background: #61469b;
}

.benefit-button1 {
  background: rgba(98, 75, 162, 0.5);
  box-shadow: 3.46725px 6.93449px 6.93449px 0px rgba(0, 0, 0, 0.25) inset;
  color: white;
  border: none;
  font-family: Satoshi;
  font-style: normal;
  font-weight: 700;
  padding: 14px 62px;
  border-radius: 50px;
}

.secondary-button.benefit-button1:hover {
  border: 1px solid #624ba2;
}

/* .secondary-button:hover{
  border: 1px solid #bc22a2;
  border-radius: 33px;
  background: #0f001b;
  color: #FFF;
} */

.explore-button {
  background-color: #6a56a5;
  color: white;
}

.secondary-button:hover {
  border: 1px solid #bc22a2;
  border-radius: 33px;
  background-color: transparent !important;
  color: #fff;
}

.navigation-button button {
  padding: 14px 51px;
  color: white;
  border: none;
  border-radius: 33px;
  background: var(--button-pink);
  transition: background-color 0.3s ease;
}

.navigation-button button:hover {
  border: 1px solid #bc22a2;
  border-radius: 33px;
  background: #0f001b;
  color: #fff;
}

.logo_container {
  margin-left: 10px;
}

.main-div {
  height: 600px;
  width: 100%;
  background-color: rgb(40, 63, 77);
}

.row {
  display: flex;
  justify-content: space-between;
  margin: 0px 0px;
}
.extra-padding-new {
  margin-bottom: 60px;
}
.card {
  background-color: red;
}

.I0AA-Banner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  background-color: #2e1f58;
  border-radius: 5px;
  position: absolute;
  width: 100%;
  z-index: 120;
}
.Banner-Text {
  font-size: 15px;
  padding: 2px;
  font-family: 'inter', sans-serif;
  margin-bottom: 0%;
  color: #fff;
}
.Banner-Button {
  border-radius: 5px;
  color: #fff;

  text-align: center;
  font-size: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 10px;
  padding-right: 10px;
  font-family: 'inter', sans-serif;
}
.Banner-Button:hover {
  background: linear-gradient(173deg, #e015a2 18.6%, #6a56a5 67.44%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.Banner-Close {
  right: 10px;
  background-color: transparent;
  color: #fff;
  border: transparent;
  position: absolute;
  text-align: center;
  padding-bottom: 3px;
}

.nav_buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.nav_button {
  background: transparent;
  color: white;
  border: 2px solid #e015a2;
  font-family: Satoshi;
  font-style: normal;
  font-weight: 700;
  padding: 14px 62px;
  border-radius: 50px;
  min-width: 150px;
  max-width: 150px;
  padding: 14px 30px;
}

.nav_button:hover {
  background: #e015a2;
  box-shadow: 3.46725px 6.93449px 6.93449px 0px rgba(0, 0, 0, 0.25) inset;
  color: white;
  border: 2px solid #e015a2;
  font-family: Satoshi;
  font-style: normal;
  font-weight: 700;
  padding: 14px 62px;
  border-radius: 50px;
  min-width: 150px;
  max-width: 150px;
  padding: 14px 30px;
}

@media (max-width: 1400px) {
  .extra-padding {
    padding: 14px 40px;
  }
}

@media (max-width: 768px) {
  .extra-padding {
    padding: 14px 51px;
    font-size: 12px;
    display: none;
  }
}

@media (max-width: 576px) {
  .extra-padding-new {
    margin-bottom: 160px;
  }

  .benefit-button1 {
    font-size: 12px;
    padding: 14px 51px;
  }

  .Banner-Text {
    font-size: 10px;
  }
  .Banner-Button {
    font-size: 10px;
  }
  .Banner-Close {
    right: 5px;
  }

  .hide-on-mobile {
    display: none;
  }

  .gradient_button {
    min-width: 245px !important;
  }
}
