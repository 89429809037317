@font-face {
  font-family: satoshi-Bold;
  src: url(../../satoshi-cufonfonts/Satoshi-Bold.otf);
}

@font-face {
  font-family: satoshi-Light;
  src: url(../../satoshi-cufonfonts/Satoshi-Light.otf);
}

@font-face {
  font-family: satoshi;
  src: url(../../satoshi-cufonfonts/Satoshi-Regular.otf);
}

@font-face {
  font-family: al-nile;
  src: url(../../satoshi-cufonfonts/AlNile.ttf);
}

/* .home-content>div:nth-child(1) {
  margin-bottom: 50px;
} */

.ecosystem-section {
  margin: 75px;
}

.banner-wrapper {
  display: flex;
  flex-direction: column;
  gap: 343px;
  justify-content: center;
  align-items: center;
}
.ai-section {
  margin: 343px 75px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.card-section-heading {
  font-size: 50px;
  font-family: Inter;
}

.card-section-text {
  font-size: 28px;
  font-weight: 400;
  font-family: Inter;
  text-align: center;
}

.card-section {
  margin: 75px;
  font-family: 'Inter';
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 60px;
}

.updated-colored-title {
  font-size: 60px;
  font-weight: 700;
  background-image: linear-gradient(
    90deg,
    #e015a2 29.2%,
    #624ba2 97.8%
  ) !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
  color: transparent !important;
  font-family: inter;
}

.map-section {
  margin: 75px;
  font-family: 'Inter';
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 60px;
}

.review-card-wrapper {
  display: flex;
  gap: 40px;
  justify-content: center;
}

.group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.heading {
  font-size: 50px !important;
  text-align: center !important;
  display: flex !important;
  justify-content: center !important;
}

.text {
  font-size: 24px;
  text-align: center;
}

.grey-color {
  background-image: linear-gradient(89.98deg, #868686 10.79%, #624ba2 99.99%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 800;
}

.satoshi-text {
  font-family: satoshi;
}

.card-item-margin {
  margin: 0px 75px;
}

.inter-text {
  font-family: inter;
}

.home-content > div:nth-child(2) .container-fluid {
  padding-top: 35px !important;
  margin-bottom: -20px;
}

.Four-stage {
  margin-bottom: 350px !important;
  margin-top: 350px !important;
}

.tradional-bottom-line-new {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 16vw !important;
  height: 4px !important;
  /* Adjust the height for thickness */
  background-color: #d231a6;
  /* Set the line color to black */
  margin-top: 39px !important;
  border-radius: 50px;
  margin: 0 auto;
}

.tradional-bottom-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 16vw !important;
  height: 4px !important;
  /* Adjust the height for thickness */
  background-color: #d231a6;
  /* Set the line color to black */
  margin-top: -15px !important;
  border-radius: 50px;
  margin: 0 auto;
}

.future-container {
  margin-top: 264px !important;
}

.stage-content {
  margin-bottom: 350px !important;
  margin-top: 264px !important;
}

.home-content > div.stem-visualization {
  margin-top: 350px !important;
  margin-bottom: 0px !important;
}

.home-content .learners {
  padding-top: 20px !important;
  padding-bottom: 30px !important;
}

.tradional-learning {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-content > div.learners > div:nth-child(1) {
  margin-top: 50px !important;
  margin-bottom: 15px !important;
}

.home-content > div:last-child {
  margin-top: 213px !important;
  margin-bottom: 120px !important;
}

.home-content .peoples > div:nth-child(2) {
  min-height: 500px;
}

.ard-section-heading {
  font-size: 50px !important;
  font-weight: 400 !important;
}

.home-content .peoples > div:nth-child(2) > div:nth-child(1) {
  position: absolute;
  top: 55%;
  left: 50.6%;
  transform: translate(-50%, -50%);
  z-index: -1;
}

.home-content .paradiagm-content {
  border-radius: 50px;
  margin: 0px 75px;
  background: rgba(98, 75, 162, 0.3);
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
}

.paradiagm-content .contentBox {
  .paradiagm-bottom-line {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    align-items: start;
    width: 18vw !important;
    height: 3px !important;
    /* Adjust the height for thickness */
    background-color: #d231a6;
    /* Set the line color to black */
    border-radius: 50px;
    margin-top: -10px !important;
    margin-bottom: 25px !important;
  }
}

.paradiagm-content .contentBox {
  display: flex;
  padding-left: 100px;
  justify-content: start;
  align-items: start;
}

.paradiagm-content .contentBox .paradiagm-title-width {
  width: 72vw !important;
}

.paradiagm-subtitle-width {
  color: #fff !important;
  text-align: center;
  font-family: Inter;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
  width: 60%;
}

.paradiagm-content .contentBox div:nth-child(1) .paradiagm-title-content {
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: start;
}

.paradiagm-content
  .contentBox
  div:nth-child(1)
  .paradiagm-first-title-gradient {
  position: absolute;
  color: #e015a2 !important;
  font-size: 44px !important;
  font-weight: 200 !important;
  padding-top: 15px !important;
  margin-bottom: -20px !important;
  font-family: satoshi !important;
}

.paradiagm-content
  .contentBox
  div:nth-child(1)
  .paradiagm-first-title-none-gradient {
  padding-left: 99px;
  font-size: 44px !important;
  font-weight: 600 !important;
  padding-top: 15px !important;
  margin-bottom: -30px !important;
  font-family: satoshi-Bold !important;
}

.paradiagm-content .contentBox div:nth-child(1) .sub-firstTitle-first {
  font-size: 40px !important;
  padding-right: 10px !important;
  padding-top: 20px !important;
  font-weight: 700;
  color: white !important;
  margin-bottom: -20px !important;
  font-family: Inter !important;
}

.paradiagm-content .contentBox div:nth-child(1) .sub-firstTitle-second {
  color: #b8bbca !important;
  font-weight: 700;
  font-size: 50px !important;
  padding-bottom: 20px !important;
  font-family: Inter !important;
}

.paradiagm-content .contentBox .paradiagm-subtitle {
  display: flex;
  justify-content: start;
}

.paradiagm-content .contentBox .paradiagm-subtitle .paradiagm-subtitle-text {
  text-align: left !important;
}

.paradiagm-content .paradiagm-img {
  display: initial;
}

.atom-img {
  position: absolute;
  width: 642px;
  bottom: -164px;
  right: 4px;
}

.paradiagm-img-box {
  display: none;
  height: 150px;
}

.paradiagm-content .paradiagm-button {
  display: flex !important;
  justify-content: start !important;
  flex-wrap: wrap;
  align-items: start;
  padding-left: 100px;
  margin-bottom: 30px;
}

.home-content .join-us {
  margin: 0px 76px;
  border-radius: 50px;
  background: linear-gradient(153.81deg, #b02686 4.14%, #2f234f 70.35%);
  height: 575px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.join-us-mobile {
  display: none;
}

.home-content .join-us > div:nth-child(1) {
  padding-top: 50px;
}

.home-content .join-us > div:nth-child(3) {
  height: 300px;
  position: absolute;
  overflow: hidden;
  background: transparent;
  width: 300px;
  bottom: 0;
  right: 0px;
  border-radius: 85px;
}

.home-content .join-us ul {
  display: flex;
  justify-content: center;
  width: 80vw !important;
}

.home-content .join-us ul li span {
  width: 38px;
  height: 38px;
  rotate: 15deg;
  font-weight: bold;
  background: white;
  color: #e015a2;
  border-radius: 100%;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-content .join-us ul li {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 22px;
  width: 370px;
}

.home-content .join-us ul li p {
  color: #fff;
  font-family: Inter;
  font-size: 22px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding-left: 5px;
  padding-top: 16px;
}

.learner-img {
  /* height: 205px; */
  margin-bottom: 34px;
  width: 70%;
}

.join-us {
  .joinUs-bottom-line {
    display: flex !important;
    flex-wrap: wrap !important;
    justify-content: center !important;
    align-items: center !important;
    width: 15vw !important;
    height: 3px !important;
    /* Adjust the height for thickness */
    background-color: #d231a6;
    /* Set the line color to black */
    border-radius: 50px;
    margin: 0 auto;
  }
}

.learners-bottom-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 47vw !important;
  height: 3.5px !important;
  /* Adjust the height for thickness */
  background-color: #d231a6;
  /* Set the line color to black */
  margin-top: -20px;
  margin-bottom: 40px !important;
  border-radius: 50px;
  margin: 0 auto;
}

.contentBox {
  padding-bottom: 10px;
}

.secondCard-container {
  padding-top: 80px;
  height: 600px;
  background-image: linear-gradient(
    180deg,
    hsl(275deg 100% 5%) 0%,
    hsl(292deg 68% 10%) 18%,
    hsl(300deg 84% 13%) 34%,
    hsl(300deg 84% 15%) 47%,
    hsl(300deg 84% 15%) 58%,
    hsl(300deg 84% 13%) 68%,
    hsl(292deg 68% 10%) 78%,
    hsl(275deg 100% 5%) 100%
  );
}

.card-content {
  height: 326px;
  position: absolute;
  top: 63%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 15px;
}

/* Style the title */
.title {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-weight: 700;
  font-size: 20px;
  font-style: normal;
  line-height: normal;
}

/* Style the pink line */
.pink-line {
  width: 50%;
  height: 3px;
  background-color: #ff66b2;
}

/* Style the subtitle */
.subtitle {
  color: #fff;
  text-align: center;
  font-family: satoshi;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  width: 100%;
}

.subtitle-cards-text {
  color: #fff;
  text-align: center;
  font-family: 'satoshi-Light';
  font-size: 20px;
  font-style: normal;
  line-height: normal;
  width: 100%;
}

.score-higher-ball {
  border-radius: 50%;
  background: linear-gradient(273deg, #fff 4.95%, #fff 93.62%);
  box-shadow: 3.46725px 6.93449px 6.93449px 0px rgba(0, 0, 0, 0.25) inset;
}

.subtitle-cards {
  color: white;
  text-align: center;
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 0 5px;
  width: 80%;
}

.subtitle-container {
  width: 80%;
}

.tradional-learning {
  width: 100%;
  margin: 10px 0 50px;
}

.join-now-btn {
  display: flex;
  justify-content: center;
}

.join-today-text {
  color: #fff;
  text-align: center;
  font-family: Inter;
  font-size: 75px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.mobile-responsive-ball,
.single-ball {
  display: none;
}

.vision-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.vision-button {
  padding: 14px 30px;
  justify-content: center;
  align-items: center;
  gap: 110px;
  border: none;
  border-radius: 50px;
  background: linear-gradient(273deg, #d231a6 4.95%, #c2128c 93.62%);
  box-shadow: 3.46725px 6.93449px 6.93449px 0px rgba(0, 0, 0, 0.25) inset;
  color: #fff;
  font-family: Satoshi;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 20px;
}

.upper-cards-line {
  margin-bottom: 110px;
}

.learnerTextcontainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.learnerText {
  color: #fff;
  text-align: center;
  font-family: Satoshi;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 48px;
  width: 661px;
  margin-bottom: 48px;
}

.heading {
  display: flex;
  gap: 15px;
}

.traditional-bottom-line {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 16vw !important;
  height: 4px !important;
  /* Adjust the height for thickness */
  background-color: #d231a6;
  /* Set the line color to black */
  margin-top: 33px !important;
  border-radius: 50px;
  margin: 0 auto;
  margin-bottom: 82px;
}

.traditional-model-subHeading {
  margin-top: -12px;
  margin-bottom: -20px;
  color: white;
  text-align: center;
  font-family: Satoshi;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/* ---------------- Responsive styles for smaller screens --------------------- */
@media (max-width: 1400px) {
  .traditional-model-subHeading {
    margin-top: -12px;
    margin-bottom: 22px;
    font-size: 28px;
    font-family: 'Inter';
    font-weight: 500;
  }

  .traditional-bottom-line {
    margin-bottom: 110px;
    margin-top: -11px !important;
  }

  .learnerText {
    font-size: 18px;
    margin-top: 32px;
    margin-bottom: 32px;
    width: 584px;
  }

  .stage-content {
    margin-top: 187px !important;
  }

  .atom-img {
    width: 632px;
    bottom: -195px;
  }

  .home-content .join-us {
    border-radius: 40px;
    margin: 0px 61px;
  }

  .future-container {
    margin-top: 187px !important;
  }

  .vision-button {
    display: none;
  }

  .upper-cards-line {
    margin-bottom: 110px;
  }

  .home-content .paradiagm-content {
    border-radius: 40px;
    margin: 0px 61px;
  }

  .title {
    font-size: 18px;
  }

  .subtitle-cards {
    font-size: 16px;
  }

  .card-content {
    width: 91%;
  }

  .subtitle {
    width: 75%;
  }

  .learner-img {
    margin-bottom: 34px;
  }

  .paradiagm-content .contentBox {
    padding-left: 79px;
  }

  .paradiagm-content .paradiagm-button {
    padding-left: 79px;
  }

  .home-content > div:last-child {
    margin-top: 160px !important;
  }
}

@media (max-width: 1200px) {
  .traditional-model-subHeading {
    margin-top: -12px;
    margin-bottom: -12px;
    font-size: 18px;
    width: 502px;
  }

  .traditional-bottom-line {
    margin-bottom: 57px;
    margin-top: 12px !important;
  }

  .learnerText {
    font-size: 16px;
    margin-top: 21px;
    margin-bottom: 21px;
    width: 511px;
  }

  .stage-content {
    margin-top: 152px !important;
  }

  .paradigm-button {
    margin-bottom: 117px;
  }

  .atom-img {
    width: 572px;
    bottom: -146px;
  }

  .paradiagm-content .contentBox {
    padding-left: 58px;
  }

  .home-content .join-us {
    border-radius: 32px;
    margin: 0px 47px;
  }

  .paradiagm-content .paradiagm-button {
    padding-left: 57px;
  }

  .home-content .paradiagm-content {
    border-radius: 32px;
    margin: 0px 47px;
  }

  .upper-cards-line {
    margin-bottom: 57px;
  }

  .title {
    font-weight: 700;
    font-size: 18px;
  }

  .subtitle {
    font-size: 12px;
  }

  .subtitle-cards {
    font-size: 16px;
  }

  .join-us {
    .joinUs-bottom-line {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 15vw !important;
      height: 3px !important;
      /* Adjust the height for thickness */
      background-color: #d231a6;
      /* Set the line color to black */
      margin-top: -20px;
      border-radius: 50px;
      margin: 20px auto;
    }
  }

  .home-content .join-us ul {
    width: 100%;
    /* flex-direction: column;  */

    display: flex;
  }

  .home-content .join-us ul li {
    margin-top: 10px;
    font-size: 15px;
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .home-content .join-us ul li p {
    font-weight: 500;
    font-size: 16px;
    padding-left: 5px;
    margin: 0;
    padding-top: 0px;
  }

  .home-content .join-us .secondary-button {
    font-size: 12px !important;
    margin-top: 30px !important;
  }

  .home-content .join-us ul li span {
    width: 35px;
    height: 35px;
    rotate: 15deg;
    font-weight: bold;
    background: white;
    color: #e015a2;
    border-radius: 100%;
    margin-right: 10px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paradiagm-content .contentBox div:nth-child(1) .sub-firstTitle-first {
    font-size: 32px !important;
  }

  .paradiagm-content .contentBox div:nth-child(1) .sub-firstTitle-second {
    font-size: 32px !important;
    margin-top: 15px;
  }

  .paradiagm-content .contentBox .paradiagm-subtitle .paradiagm-subtitle-text {
    width: 521px;
  }

  .paradiagm-content
    .contentBox
    div:nth-child(1)
    .paradiagm-first-title-none-gradient {
    font-size: 32px !important;
    padding-left: 75px;
  }

  .paradiagm-content
    .contentBox
    div:nth-child(1)
    .paradiagm-first-title-gradient {
    font-size: 32px !important;
  }

  .home-content > div:last-child {
    margin-top: 152px !important;
  }

  /* .paradiagm-content .contentBox {
    display: flex;
    padding-left: 5px;
    justify-content: center;
    align-items: center;
  }

  .paradiagm-content .contentBox div:nth-child(1) .paradiagm-title-content {
    padding-top: 50px !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paradiagm-content .contentBox div:nth-child(1) .paradiagm-first-title-gradient {
    position: absolute;
    padding-right: 130px;
    color: #E015A2 !important;
    font-size: 40px !important;
    font-weight: 200 !important;
  }

  .paradiagm-content .contentBox div:nth-child(1) .paradiagm-first-title-none-gradient {
    padding-left: 100px;
    font-size: 40px !important;
    font-weight: 500 !important;
    padding-bottom: 10px !important;
  }

  .paradiagm-content .contentBox div:nth-child(1) .sub-firstTitle-first {
    font-size: 53px !important;
    text-align: center !important;
    padding-right: 10px !important;
    margin-bottom: -10px !important;
  }

  .paradiagm-content .contentBox div:nth-child(1) .sub-firstTitle-second {
    text-align: center !important;
    color: #B8BBCA !important;
    font-size: 53px !important;
    padding-bottom: 20px !important;
  }
 */

  /* .paradiagm-content .contentBox .paradiagm-bottom-line {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 25vw !important;
    height: 3px !important; */
  /* Adjust the height for thickness */
  /* background-color: #D231A6; */
  /* Set the line color to black */
  /* border-radius: 50px;
    margin: 20px auto !important;
  } */

  /* .paradiagm-content .contentBox .paradiagm-subtitle {
    font-size: 17px !important;
    display: flex;
    justify-content: center;
  }

  .paradiagm-content .contentBox .paradiagm-subtitle .paradiagm-subtitle-text {
    text-align: center !important;
    font-size: 13.5px !important;
  }

  .paradiagm-content .paradiagm-img {
    display: block;
  } */

  /* .atom-img {
    position: absolute;
    width: 350px;
    bottom: -30px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .paradiagm-img-box {
    display: block;
  }

  .paradiagm-content .paradiagm-button {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 0px !important;

  }

  .learner-img {
    margin-bottom: 21px;
    height: 177px;
  } */

  /* .atom-img {
    width: 572px;
    padding-left:50px ;
  } */
}

/* --------------------------------------*/

@media (max-width: 991px) {
  .traditional-bottom-line {
    margin-bottom: 72px;
    margin-top: 12px !important;
  }

  .card-item-margin {
    margin: 0 39px;
    font-size: 14px;
  }

  .learnerText {
    font-size: 14px;
    margin-top: 22px;
    margin-bottom: 22px;
    width: 447px;
  }

  .home-content .join-us {
    border-radius: 24px;
    display: none;
  }

  .higher-ball {
    display: none;
  }

  .upper-cards-line {
    margin-bottom: 72px;
  }

  .home-content .paradiagm-content {
    border-radius: 24px;
    margin: 0px 39px;
  }

  .paradigm-button {
    margin-bottom: 109px;
  }

  .contentbox-title-text {
    margin-top: 109px;
  }

  .title {
    font-size: 16px;
  }

  .pink-line {
    width: 40%;
  }

  .card-content {
    width: 100%;
  }

  .subtitle-cards {
    font-size: 14px;
    width: 80%;
  }

  .join-us-mobile {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 15px;
  }

  .join-octi-learn {
    width: 100%;
    margin: 0px 20px;
    height: 472px;
    background: linear-gradient(154deg, #e015a2 -14.83%, #412a81 82.99%);
    border-radius: 24px;
  }

  .degree-img {
    height: 276.838px;
    flex-shrink: 0;
    margin-top: -70px;
  }

  .join-today-text {
    font-family: Inter;
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: -89px;
  }

  .join-text-p {
    align-items: center;
    justify-content: center;
    display: flex;
    color: #fff;
    text-align: center;
    padding: 0px 16px;
    font-family: Satoshi;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .pink-line-end {
    width: 127px;
    height: 0px;
    flex-shrink: 0;
    stroke-width: 1px;
    stroke: #d231a6;
  }

  .ball-container {
    display: flex;
    /* flex-direction: ;  */
    align-items: center;
    justify-content: space-around;
    padding: 0px 13px;
    margin-top: 61px;
  }

  .ball-pair {
    display: flex;
    align-items: center;
  }

  .small-ball {
    border-radius: 50%;
    background: linear-gradient(273deg, #fff 4.95%, #fff 93.62%);
    box-shadow: 3.46725px 6.93449px 6.93449px 0px rgba(0, 0, 0, 0.25) inset;
  }

  .tick {
    color: #b51a98;
    margin-top: 2px;
    padding: 7px;
  }

  .personalizrd-text {
    color: #fff;
    text-align: center;
    margin: 0px;
    font-family: Satoshi;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 12px;
  }

  .join-now-btn {
    margin-top: 50px;
    margin-left: 13px;
  }

  .now-btn {
    border-radius: 50px;
    background: linear-gradient(273deg, #d231a6 4.95%, #c2128c 93.62%);
    display: flex;
    height: 30px;
    padding: 14px 51px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-size: 12px;
    color: #fff;
    border: none;
  }

  .join-bulb {
    height: 200px;
    top: -50px;
    right: 12px;
  }

  .join-bulb-container {
    display: flex;
    justify-content: end;
    margin-top: -92px;
  }

  .book-container {
    width: 300px;
    height: 400px;
    position: relative;
  }

  .book-img {
    position: absolute;
    top: -174px;
    left: 0;
    transform: rotate(357deg);
    margin-left: 20px;
    height: 100px;
  }

  .ball-container-1 {
    padding: 0px 13px;
    margin-top: 15px;
  }

  .home-content .join-us ul {
    width: 50vw !important;
    flex-direction: column;
    /* Stack list items vertically */
    display: flex;
  }

  .home-content .join-us ul li {
    margin-top: 10px;
    font-size: 15px;
    width: 100%;
    margin-bottom: 10px;
    justify-content: start;
    align-items: start;
  }

  .home-content .join-us ul li p {
    font-weight: 600;
    font-size: 17px;
    padding-left: 5px;
    margin: 0;
  }

  .home-content .join-us .secondary-button {
    font-size: 13px !important;
  }

  .home-content .join-us ul li span {
    width: 35px;
    height: 35px;
    rotate: 15deg;
    font-weight: bold;
    background: white;
    color: #e015a2;
    border-radius: 100%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* .paradiagm-content .contentBox {
    display: flex;
    padding-left: 5px;
    justify-content: center;
    align-items: center;
  } */

  /* .paradiagm-content .contentBox div:nth-child(1) .paradiagm-title-content {
    padding-top: 50px !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  /* .paradiagm-content .contentBox div:nth-child(1) .paradiagm-first-title-gradient {

    position: absolute;
    padding-right: 130px;
    color: #E015A2 !important;
    font-size: 40px !important;
    font-weight: 200 !important;
  } */

  /* .paradiagm-content .contentBox div:nth-child(1) .paradiagm-first-title-none-gradient {
    padding-left: 100px;
    font-size: 40px !important;
    font-weight: 500 !important;
    padding-bottom: 10px !important;
  } */

  /* .paradiagm-content .contentBox div:nth-child(1) .sub-firstTitle-first {
    font-size: 53px !important;
    text-align: center !important;
    padding-right: 10px !important;
    margin-bottom: -10px !important;
  }

  .paradiagm-content .contentBox div:nth-child(1) .sub-firstTitle-second {
    text-align: center !important;
    color: #B8BBCA !important;
    font-size: 53px !important;
    padding-bottom: 20px !important;
  } */

  /* .paradiagm-content .contentBox .paradiagm-bottom-line {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 25vw !important;
    height: 3px !important; */
  /* Adjust the height for thickness */
  /* background-color: #D231A6; */
  /* Set the line color to black */
  /* border-radius: 50px;
    margin: 20px auto !important;
  } */

  /* .paradiagm-content .contentBox .paradiagm-subtitle {
    font-size: 17px !important;
    display: flex;
    justify-content: center;
  } */

  /* .paradiagm-content .contentBox .paradiagm-subtitle .paradiagm-subtitle-text {
    text-align: center !important;
    font-size: 13px !important;
  } */

  /* .paradiagm-content .paradiagm-button {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 0px !important;
    margin-bottom: 40px !important;
  } */
  .paradiagm-content .contentBox {
    padding-left: 39px;
  }

  .paradiagm-content .paradiagm-button {
    padding-left: 39px;
  }

  .learner-img {
    margin-bottom: 25px;
    /* height: 162px; */
  }

  .atom-img {
    width: 456px;
    bottom: -67px;
    right: -18px;
  }

  .home-content > div:last-child {
    margin-top: 111px !important;
  }
}

/* --------------------------------------*/

@media screen and (max-width: 768px) {
  .traditional-bottom-line {
    margin-bottom: 65px;
    margin-top: 3px !important;
  }

  .traditional-model-subHeading {
    width: 100%;
  }

  .tradional-bottom-line-new {
    margin-top: 8px !important;
  }

  .card-content {
    gap: 8px;
    width: 100%;
    padding-top: 37px;
  }

  .home-content > div:last-child {
    margin-top: 94px !important;
  }

  .learnerText {
    font-size: 12px;
    margin-top: 16px;
    margin-bottom: 16px;
    width: 384px;
  }

  .join-octi-learn {
    border-radius: 14px;
    height: 360px;
  }

  .atom-img {
    width: 368px;
    bottom: -93px;
    right: -18px;
  }

  .item-margin {
    margin-bottom: 86px;
  }

  .paradiagm-content .paradiagm-button {
    margin-bottom: 0;
  }

  .upper-cards-line {
    margin-bottom: 65px;
  }

  .home-content .join-us {
    border-radius: 14px;
    width: 80%;
    height: auto;
    display: none;
    padding: 80px 40px 40px;
  }

  .degree-img {
    height: 188px;
  }

  .join-today-text {
    font-size: 36px;
    margin-top: -59px;
  }

  .join-text-p {
    font-size: 12px;
  }

  .book-img {
    height: 74px;
    top: -124px;
  }

  .join-bulb {
    height: 164px;
  }

  .now-btn {
    padding: 10px 30px;
  }

  .ball-container {
    margin-top: 42px;
    justify-content: space-evenly;
  }

  .home-content .paradiagm-content {
    border-radius: 14px;
    margin: 0px 20px;
  }

  .future-container {
    margin-top: 158px !important;
  }

  .paradiagm-content .contentBox .paradiagm-subtitle-width {
    width: 387px !important;
  }

  .card-item-margin {
    margin: 0px 20px;
  }

  .home-content .join-us > div:nth-child(3) {
    display: none;
    /* Hide the third div on smaller screens */
  }

  /* .home-content .join-us ul li {
    margin-top: 10px;
    font-size: 15px;
    width: 100%;
    margin-bottom: 10px;
  } */

  .join-us {
    .joinUs-bottom-line {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      width: 15vw !important;
      height: 3px !important;
      /* Adjust the height for thickness */
      background-color: #d231a6;
      /* Set the line color to black */
      margin-top: -20px;
      border-radius: 50px;
      margin: 20px auto;
    }
  }

  .home-content .join-us ul {
    width: 50vw !important;
    padding-top: 30px;
    flex-direction: column;
    display: flex;
  }

  .title {
    font-size: 12px;
    font-weight: 700;
  }

  .pink-line {
    width: 40%;
  }

  .subtitle {
    font-size: 13px;
    padding: 10px;
    /* width: 100%; */
  }

  .subtitle-cards {
    font-size: 12px;
    width: 100%;
    font-weight: 400;
  }

  .home-content .join-us ul li {
    margin-top: 10px;
    font-size: 15px;
    width: 100%;
    margin-bottom: 10px;
    justify-content: start;
    align-items: start;
  }

  .home-content .join-us .secondary-button {
    margin-top: 30px !important;
  }

  .home-content .join-us ul li span {
    width: 35px;
    height: 35px;
    rotate: 15deg;
    font-weight: bold;
    background: white;
    color: #e015a2;
    border-radius: 100%;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* --------------------------------- */

  /* .paradiagm-content .contentBox {
    display: flex;
    padding-left: 5px;
    justify-content: center;
    align-items: center;
  } */

  /* .paradiagm-content .contentBox div:nth-child(1) .paradiagm-title-content {
    padding-top: 50px !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  } */

  /* .paradiagm-content .contentBox div:nth-child(1) .paradiagm-first-title-gradient {

    position: absolute;
    padding-right: 130px;
    color: #E015A2 !important;
    font-size: 37px !important;
    font-weight: 200 !important;
  } */

  /* .paradiagm-content .contentBox div:nth-child(1) .paradiagm-first-title-none-gradient {
    padding-left: 100px;
    font-size: 37px !important;
    font-weight: 500 !important;
    padding-bottom: 10px !important;
  } */

  /* .paradiagm-content .contentBox div:nth-child(1) .sub-firstTitle-first {
    font-size: 48px !important;
    text-align: center !important;
    padding-right: 10px !important;
    margin-bottom: -10px !important;
  } */

  /* .paradiagm-content .contentBox div:nth-child(1) .sub-firstTitle-second {
    text-align: center !important;
    color: #B8BBCA !important;
    font-size: 48px !important;
    padding-bottom: 20px !important;
  } */

  /* .paradiagm-content .contentBox .paradiagm-bottom-line {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 25vw !important;
    height: 3px !important; */
  /* Adjust the height for thickness */
  /* background-color: #D231A6; */
  /* Set the line color to black */
  /* border-radius: 50px;
    margin: 20px auto !important;
  } */

  /* .paradiagm-content .contentBox .paradiagm-subtitle {
    font-size: 17px !important;
    display: flex;
    justify-content: center;
  } */

  /* .paradiagm-content .contentBox .paradiagm-subtitle .paradiagm-subtitle-text {
    text-align: center !important;
    font-size: 11.5px !important;
  } */

  /* .paradiagm-content .paradiagm-button {
    display: flex !important;
    justify-content: center !important;
    align-items: center;
    flex-wrap: wrap;
    padding-left: 0px !important;
    margin-bottom: 30px !important;
  } */

  .paradiagm-content .contentBox div:nth-child(1) .sub-firstTitle-first {
    font-size: 24px !important;
  }

  .paradiagm-content .contentBox div:nth-child(1) .sub-firstTitle-second {
    font-size: 24px !important;
  }

  .paradiagm-content .contentBox {
    padding-left: 49px;
  }

  .paradiagm-content .paradiagm-button {
    padding-left: 36px;
  }

  .contentbox-title-text {
    margin-top: 86px;
  }

  .paradiagm-content .contentBox .paradiagm-subtitle .paradiagm-subtitle-text {
    width: 363px;
  }

  .learner-img {
    margin-bottom: 13px;
    /* height: 117px; */
  }
}

/* --------------------------------------*/

@media (max-width: 576px) {
  .updated-colored-title {
    font-size: 32px;
  }

  .card-section-heading {
    font-size: 32px !important;
  }

  .card-section-text {
    font-size: 18px !important;
  }

  .ecosystem-section {
    margin: 15px;
  }

  .card-section {
    margin: 180px 15px;
  }

  .ai-section {
    margin: 250px 15px;
  }

  .map-section {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }

  .banner-wrapper {
    gap: 200px;
  }

  .review-card-wrapper {
    flex-wrap: wrap;
  }

  .traditional-bottom-line {
    margin-bottom: 43px;
    margin-top: 18px !important;
  }

  .learnerText {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 42px;
    width: 309px;
  }

  .card-content {
    width: 100%;
  }

  .paradigm-button {
    margin-left: 150px;
    margin-bottom: 150px;
  }

  .atom-img {
    /* -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); */
    top: -172px;
    width: 70%;
    left: 15%;
    right: 0%;
  }

  /* .atom-img {
    top: -172px;
    width: 350px;
    left: 117px;
  } */

  .now-btn {
    font-size: 12px;
    padding: 14px 35px;
  }

  .subtitle-container {
    width: 100%;
  }

  .paradiagm-content .contentBox {
    padding-left: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .paradiagm-content .contentBox .paradiagm-bottom-line {
    margin-bottom: 20px !important;
    margin-top: 11px !important;
    margin-left: 0px;
  }

  .stage-content {
    margin-bottom: 258px !important;
    margin-top: 158px !important;
  }

  .join-octi-learn {
    border-radius: 20px;
    height: 675px;
  }

  .join-today-text {
    font-size: 32px;
    margin-top: 20px;
  }

  .future-container {
    margin-top: 158px !important;
  }

  .upper-cards-line {
    margin-bottom: 43px;
  }

  .degree-img {
    height: 276px;
  }

  .join-text-p {
    font-size: 16px;
  }

  .join-text {
    padding: 0px 13px;
    width: 365px;
  }

  .card-item-margin {
    margin: 43px 20px;
  }

  .tradional-bottom-line-new {
    width: 127px !important;
    height: 2px !important;
    margin-top: 23px;
  }

  .home-content .join-us {
    border-radius: 14px;
  }

  .home-content .paradiagm-content {
    border-radius: 20px;
    margin: 0px 20px;
    margin-top: 139px !important;
  }

  .paradiagm-content .contentBox div:nth-child(1) .paradiagm-title-content {
    padding-top: 0px !important;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 32px !important;
  }

  .paradiagm-content
    .contentBox
    div:nth-child(1)
    .paradiagm-first-title-gradient {
    padding-top: 0px !important;
    position: absolute;
    padding-right: 105px;
    color: #e015a2 !important;
    font-size: 35px !important;
    font-weight: 200 !important;
  }

  .paradiagm-content
    .contentBox
    div:nth-child(1)
    .paradiagm-first-title-none-gradient {
    padding-left: 100px;
    padding-top: 0px !important;
    font-size: 35px !important;
    font-weight: 500 !important;
    padding-bottom: 10px !important;
  }

  .paradiagm-content .contentBox div:nth-child(1) .sub-firstTitle-first {
    font-size: 23px !important;
    text-align: center !important;
    padding-right: 0px !important;
    margin-bottom: -17px !important;
    font-family: inter !important;
    padding-top: 16px !important;
  }

  .paradiagm-content .contentBox div:nth-child(1) .sub-firstTitle-second {
    text-align: center !important;
    color: #b8bbca !important;
    font-size: 25px !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px !important;
    margin-left: 0px;
  }

  .paradiagm-content .contentBox .paradiagm-subtitle {
    font-size: 17px !important;
    display: flex;
    justify-content: center;
  }

  .paradiagm-content .contentBox .paradiagm-subtitle-width {
    width: 100% !important;
  }

  .paradiagm-content .contentBox .paradiagm-subtitle .paradiagm-subtitle-text {
    text-align: center !important;
    font-size: 11px !important;
  }

  .paradiagm-content .contentBox .paradiagm-subtitle .paradiagm-subtitle-text {
    text-align: center !important;
    font-size: 12px !important;
    padding: 0 90px;
    margin-top: 21px;
    margin-bottom: 29px;
    width: 100%;
    margin-left: 0px;
  }

  .paradiagm-content .paradiagm-button {
    padding-left: 0px;
    justify-content: center !important;
  }

  .paradigm-button {
    margin-left: 0px;
  }

  .home-content > div:last-child {
    margin-top: 123px !important;
  }

  .title {
    font-size: 14px;
    font-family: inter;
  }

  .subtitle {
    font-size: 12px;
    font-family: Satoshi;
  }

  .ball-container {
    display: none;
  }

  .mobile-responsive-ball {
    display: flex;
    justify-content: space-between;
    margin-top: 43px;
  }

  .single-ball {
    display: block;
  }

  .ball-pair {
    display: flex;
    align-items: center;
    margin-right: 23px;
    margin-left: 13px;
    margin-bottom: 25px;
  }

  .book-img {
    transform: rotate(304deg);
    top: -77px;
    height: 62px;
  }

  .higher-ball {
    display: flex;
    align-items: center;
    margin-left: 13px;
  }

  .round-ball {
    border-radius: 50%;
    background: linear-gradient(273deg, #fff 4.95%, #fff 93.62%);
    box-shadow: 3.46725px 6.93449px 6.93449px 0px rgba(0, 0, 0, 0.25) inset;
  }

  .personal-text {
    color: #fff;
    text-align: center;
    margin: 0px;
    font-family: Satoshi;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 7px;
  }

  .tick-check {
    color: #b51a98;
    margin-top: 2px;
    padding: 7px;
  }

  .join-now-btn {
    margin-top: 32px;
    margin-left: -232px;
    margin-bottom: 15px;
  }

  .join-bulb {
    height: 179px;
    margin-right: 12px;
  }

  .learner-img {
    margin-bottom: 26px;
    /* height: 81px; */
  }

  .home-content .learners {
    padding-top: 0px !important;
  }

  .contentbox-title-text {
    margin-top: 63px;
  }

  .traditional-model-subHeading {
    margin-top: -12px;
    margin-bottom: -12px;
    font-size: 14px;
    padding: 20px;
  }

  .traditional-bottom-line {
    margin-bottom: 43px;
    margin-top: 30px !important;
  }
}

@media (max-width: 430px) {
  .traditional-bottom-line {
    margin-bottom: 43px;
    margin-top: 36px !important;
  }

  .tradional-bottom-line-new {
    margin-top: 25px !important;
  }

  .learnerText {
    font-size: 16px;
    margin-top: 20px;
    margin-bottom: 42px;
    width: 309px;
  }

  .paradiagm-content .contentBox .paradiagm-bottom-line {
    margin-left: 0px;
  }

  .paradiagm-content .contentBox .paradiagm-subtitle .paradiagm-subtitle-text {
    padding: 0px 41px;
  }

  .paradiagm-content .contentBox {
    padding-left: 0px;
  }

  .secondary-button {
    margin-left: 8px;
  }

  .atom-img {
    width: 90%;
    left: 5%;
    right: 0%;
    top: -262px;
    /* width: 350px;
    left: 27px; */
  }

  .paradigm-button {
    margin-left: 0px;
    margin-bottom: 250px;
  }

  .traditional-model-subHeading {
    margin-top: -12px;
    margin-bottom: -12px;
    font-size: 12px;
    width: 100%;
  }

  .paradiagm-content .contentBox .paradiagm-title-width {
    width: 50vw !important;
  }
}

/*People Love Us Section By Hassaan Hanif*/
.peoples-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

/*People Love Us Section By Hassaan Hanif*/
.peoples {
  width: 1354px;
  height: 1270px;
  flex-shrink: 0;
  border-radius: 50px;
  border-radius: 50px;
  background: rgba(98, 75, 162, 0.3);
  box-shadow: 3.46725px 6.93449px 6.93449px 0px rgba(0, 0, 0, 0.25) inset;
}

/*People Love Us Section By Hassaan Hanif*/
.peoples-sub1 {
  height: 25%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/*People Love Us Section By Hassaan Hanif*/
.peoples-header-text1 {
  color: #fff;
  text-align: center;
  font-family: 'Inter';
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

/*People Love Us Section By Hassaan Hanif*/
.peoples-header-text2 {
  font-family: 'Inter';
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  background: linear-gradient(173deg, #e015a2 18.6%, #6a56a5 67.44%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*People Love Us Section By Hassaan Hanif*/
.peoples-subheader-text {
  color: #b8bbca;
  text-align: center;
  font-family: 'Inter';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

/*People Love Us Section By Hassaan Hanif*/
.peoples-header-sub {
  margin-top: 115px;
}

/*People Love Us Section By Hassaan Hanif*/
.peoples-subheader-sub {
  width: 661px;
  height: 66px;
}

/*People Love Us Section By Hassaan Hanif*/
.peoples-line-sub {
  margin-top: 18px;
}

/*People Love Us Section By Hassaan Hanif*/
.people-line-pic {
  width: 253px;
}

/*People Love Us Section By Hassaan Hanif*/
.peoples-sub2 {
  height: 75%;
}

.standardized-cards {
  margin: 0px;
}

/*People Love Us Section By Hassaan Hanif*/
/* Adjust styles for screens smaller than 960px */
@media screen and (max-width: 960px) {
  .peoples {
    width: 80vw;
  }

  /* .peoples-sub1 {

  } */
  .peoples-header-sub {
    margin-top: 3.5vh;
  }

  .peoples-line-sub {
    margin-top: 0vh;
  }
}

/*People Love Us Section By Hassaan Hanif*/
/* Adjust styles for screens smaller than 720px */
@media screen and (max-width: 720px) {
  .home-content > div:last-child {
    margin-top: 123px !important;
  }

  .peoples {
    width: 90vw;
  }
}

/*People Love Us Section By Hassaan Hanif*/
/* Adjust styles for screens smaller than 480px */
@media screen and (max-width: 480px) {
  .peoples {
    width: 95vw;
  }
}

/*Video Section By Hassaan Hanif*/
.video-section {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1000px;
}

@media (max-width: 355px) {
  .title {
    font-size: 14px;
    font-family: inter;
  }

  .pink-line {
    width: 40%;
  }

  .subtitle {
    font-size: 12px;
    font-family: Satoshi;
  }
}
