@font-face {
  font-family: satoshi-Bold;
  src: url(../../../satoshi-cufonfonts/Satoshi-Bold.otf);
}

@font-face {
  font-family: satoshi-Light;
  src: url(../../../satoshi-cufonfonts/Satoshi-Light.otf);
}

@font-face {
  font-family: satoshi;
  src: url(../../../satoshi-cufonfonts/Satoshi-Regular.otf);
}
.mobile-menu-container {
  display: none;
}

.wrapper {
  position: relative;
  height: 600px;
  width: 100%;
}

.bg_img {
  width: 100%;
  height: 160%;
  object-fit: cover;
  position: absolute;
  top: -183px;
  left: 0;
}

.details {
  position: absolute;
  z-index: 100;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 30px;
}

.content p {
  color: #dcdcdcde;
  font-style: normal;
  font-family: satoshi !important;
  line-height: normal;
  margin: 0;
  margin-bottom: 21px !important;
  text-align: center;
  margin-top: 21px;
}

.content div {
  display: flex !important;
  justify-content: center;
  width: 100%;
}

h1 {
  color: #fff;
  font-size: 65px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 1px;
  text-align: center;
}

.tagline_container {
  margin-top: 20px;
  font-family: inter !important;
  font-weight: 800 !important;
  font-size: 77px !important;
  background-image: linear-gradient(152deg, #e015a2 21.52%, #6840d8 94.24%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  position: relative;
}

.lines {
  position: absolute;
  left: 0;
  top: 90px;
}

.joinList-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.joinList {
  background: linear-gradient(
    90deg,
    rgba(224, 21, 162, 0.5) 43%,
    rgba(122, 11, 88, 0.5) 83%
  );
  border-radius: 83px;
  font-size: 16px;
  font-weight: 400;
  color: white;
  margin-bottom: 60px;
  max-width: 330px;
  padding: 5px 20px;
  gap: 5px;
  display: flex;
}

.joinList .countires {
  font-weight: 700;
}

.dashboard {
  width: 100%;
  position: relative;
  min-height: 600px;
}

.dashboard img {
  position: absolute;
  width: 80%;
  top: -270px;
  left: 120px;
}

.gradient_button {
  background: linear-gradient(90.06deg, #e015a2 -10.53%, #2f234f 100.54%);
  color: white;
  padding: 14px 62px;
  border-radius: 50px;
  padding: 14px 30px;
  border: none;
  outline: none;
  min-width: 230px !important;
}

.octi-learn-logo {
  margin-left: -60px;
  width: 100%;
}

.hamburger-container {
  height: 50px;
  display: none !important;
}

.welcome-design {
  font-family: inter;
  color: white;
  font-size: 77px !important;
  line-height: 1.5rem;
  font-weight: 500;
}

@media (max-width: 576px) {
  .dashboard {
    min-height: 300px;
  }

  .lines {
    width: 100%;
  }

  .dashboard img {
    top: -170px;
    left: 30px;
  }

  .gradient_button {
    min-width: 245px !important;
  }

  .pinnacle {
    font-size: 12px !important;
    margin-top: 20px !important;
  }

  .subtitle-1 {
    font-size: 10px !important;
    /* color: red !important; */
  }

  .welcome-design {
    color: white !important;
    font-size: 32px !important;
    line-height: 2rem;
  }

  .octi-learn-logo {
    height: 100px;
    margin-left: -90px;
  }

  .hamburger-container {
    height: 50px;
    display: none !important;
  }
}

@media (max-width: 768px) {
  .hamburger-container {
    height: 50px;
    cursor: pointer;
    display: block !important;
  }
  .mobile-menu-container {
    display: block;
    width: 99vw;
    height: 100vh;
    position: absolute;
    right: 1px;
    top: 1px;
    flex-direction: column;
    background-color: #0f001b;
    z-index: 9999;
    padding-top: 50px;
    overflow-y: scroll;
  }
  .mobile-menu-top-p {
    font-family: Satoshi;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    width: 90%;
    color: #b8bbca;
    margin-top: 10px;
  }
  .octi-name {
    color: #bd23a2;
  }
  .mobile-menu-main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .mobile-menu-top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .menu-hr {
    width: 80%;
    margin-top: 30px;
    margin-bottom: 30px;
    opacity: 1;
  }

  .navigation_menu-mobile {
    display: flex;
    flex-direction: column;
    color: #e0dadad7;
    list-style: none;
    cursor: pointer;
    gap: 50px;
    width: 100%;
    text-align: center;
    padding: 0px !important;
  }

  .navigation_menu-mobile ul li {
    text-align: justify;
    display: flex;
    list-style: none;
    font-family: 'Inter';
    transition: all 0.5s;
    cursor: pointer;
  }

  .navigation_menu-mobile ul li:hover {
    color: #6a56a5;
  }
  .exit-btn-div {
    position: absolute;
    right: 30px;
    cursor: pointer;
  }
  .mobile-menu-footer-main {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
  }
  .mobile-menu-footer-icon {
    background-color: rgba(98, 75, 162, 0.2);
    width: 90%;
    height: 100px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .mobile-menu-footer-icon > p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #e015a2;
  }
  .mobile-menu-wait {
    margin-top: 10px;
    width: 80%;
    text-align: center;
    color: rgba(184, 187, 202, 1);
    font-size: 16px;
    font-weight: 400;
  }
}
