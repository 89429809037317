.BH-Wrapper {
  font-family: 'Inter';
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
}

.header-title {
  font-weight: 400;
  font-size: 60px;
  text-align: center;
  display: flex;
  justify-content: center;
  color: white;
}

.header-colorText {
  background-image: linear-gradient(90deg, #e015a2 29.2%, #624ba2 97.8%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 800;
}

.header-text {
  text-align: center;
  color: white;
  font-size: 30px;
  font-weight: 500;
}

.gradient_button {
  background: linear-gradient(90.06deg, #e015a2 -10.53%, #2f234f 100.54%);
  color: white;
  padding: 14px 62px;
  border-radius: 50px;
  min-width: 150px;
  padding: 14px 30px;
  border: none;
  outline: none;
}

@media (max-width: 576px) {
  .header-title {
    font-size: 32px !important;
    display: flex;
    flex-direction: column;
  }

  .header-text {
    font-size: 20px !important;
  }
}
