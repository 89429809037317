@import url('https://fonts.googleapis.com/css?family=Inter');
@import url('https://fonts.cdnfonts.com/css/satoshi');

:root {
  --background: #0e001a;
  --button-pink: #e015a2bd;
}

body {
  margin: 0;
  background-color: var(--background) !important;
}
