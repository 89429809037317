@font-face {
  font-family: satoshi-Bold;
  src: url(../../../satoshi-cufonfonts/Satoshi-Bold.otf);
}
@font-face {
  font-family: satoshi-Light;
  src: url(../../../satoshi-cufonfonts/Satoshi-Light.otf);
}
.card-container {
  display: flex;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  border: 3px solid #61469b80;
  min-height: 300px;
  padding: 30px !important;
}

.card-container:hover {
  background: linear-gradient(25.2deg, #251038 45.4%, #4f2376 99.07%);
  transition: 300ms ease;
}

.card-parent-container:hover .hover-icon svg path {
  fill: white !important;
  stroke: white !important;
}

.icon_container {
  width: 100%;
  margin-bottom: 40px;
}

.icon_circle {
  background: #26133f;
  height: 62px;
  width: 62px;
  border-radius: 100%;
  border: 1.31px solid #16062b;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-parent-container {
  padding: 0px 12px;
}

.card-container p {
  margin: 0;
  color: white;
}

.card-container .title,
.subtitle {
  color: #ffffff !important;
  font-weight: 600;
  font-family: Inter;
  font-size: 24px;
  text-align: start !important;
  width: 100%;
}

.first-card-title,
.first-card-subtitle {
  font-family: inter !important;
  color: #ffffff !important;
  /* font-weight: 500 !important; */
  font-size: 22px !important ;
  font-weight: 700;
}

.first-card-subtitle {
  padding-bottom: 10px !important;
}

.card-container p.para {
  font-family: satoshi !important;
  font-size: 20px !important ;
}
.card-container .title,
.subtitle {
  color: #fff !important;
  font-size: 22px !important;
  font-size: Inter !important;
}
.secondCard-container .card-container {
  background: #0b0114;
}
.secondCard-container .title {
  color: #895ffe !important;
}
.card-container .title,
.subtitle {
  font-size: 20px;
  font-size: Inter !important;
}

@media (max-width: 1400px) {
  .card-container .title,
  .subtitle {
    color: #ffffff !important;
    font-weight: 400;
    font-size: 25px;
  }

  .first-card-title,
  .first-card-subtitle {
    font-family: Inter !important;
    font-weight: 700;
    color: #ffffff !important;
    font-weight: 700 !important;
    font-size: 20px !important ;
  }

  .card-container p.para {
    font-family: satoshi !important;
    font-size: 20px !important ;
    font-weight: 300 !important;
  }

  .card-container {
    min-height: 320px;
  }
}
@media (max-width: 1200px) {
  .card-container {
    min-height: 320px;
  }

  .card-container {
    margin-top: 20px;
  }
  .card-container p.para {
    font-size: 16px !important;
  }

  .card-container .title,
  .subtitle {
    font-size: 18px !important;
  }
}
@media (min-width: 768px) and (max-width: 922px) {
  .card-container .title,
  .subtitle {
    color: #ffffff !important;
    font-weight: 400;
    font-size: 26px;
  }

  .first-card-title,
  .first-card-subtitle {
    font-family: Inter !important;
    color: #ffffff !important;
    font-size: 20px !important ;
  }

  .card-container p.para {
    font-family: satoshi !important;
    font-size: 20px !important ;
    font-weight: 300 !important;
    color: hsl(0, 0%, 100%) !important;
  }
}

@media (max-width: 991px) {
  .card-container p.para {
    font-size: 13px !important ;
  }
  .card-container .title,
  .subtitle {
    font-size: 16px !important;
  }
  .card-container {
    min-height: 229px;
    padding-top: 39px;
  }
  /* .card-content{
    top: 68px;
  } */
}
@media screen and (max-width: 768px) {
  .card-container .card-bottom-line {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 9vw !important;
    height: 2px !important;
    background-color: #e015a2 !important;
    opacity: 0.5;
    border-radius: 50px;
    margin: 20px auto;
  }
  .card-container .title,
  .subtitle {
    font-size: 12px !important;
  }

  .card-container p.para {
    font-size: 12px !important;
  }
  .card-container {
    min-height: 207px;
    padding-top: 31px;
  }
}

@media (max-width: 576px) {
  .card-parent-container {
    padding: 0px 6px !important;
    margin-bottom: 12px !important;
  }

  .card-container {
    min-height: 266px;
  }
  .card-container p {
    margin: -13px;
  }

  .first-card-title,
  .first-card-subtitle {
    font-family: Inter !important;
    font-size: 22px !important ;
    color: #fff !important;
  }
  /* heading */
  .card-container .title,
  .subtitle {
    color: #fff !important;
    font-size: 14px !important;
    font-size: Inter !important;
    text-align: start !important;
  }
  /* sub-heading */
  .card-container p.para {
    font-family: Satoshi !important;
    font-size: 12px !important ;
    color: #fff !important;
    padding-left: 10px;
  }
}
@media (max-width: 430px) {
  .card-container {
    margin-top: 13px;
    min-height: 300px !important;
  }
}
