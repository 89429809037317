
@font-face {
    font-family: satoshi-Bold;
    src: url(../../../satoshi-cufonfonts/Satoshi-Bold.otf);
  }
  @font-face {
    font-family: satoshi;
    src: url(../../../satoshi-cufonfonts/Satoshi-Regular.otf);
  }
  
button{
    font-family: satoshi-Bold !important;
}

.secondary-button-hover-effect:hover{
  border: 1px solid #bc22a2;
  border-radius: 33px;
  background-color: transparent !important;
  color: #FFF;
}

.secondary-button{
  border-radius: 50px;
  background: var(--button-pink);
  padding: 14px 51px;
  font-size: 16px;
  color: white;
  border: 1px solid transparent;
  box-shadow: 3.46725px 6.93449px 6.93449px 0px rgba(0, 0, 0, 0.25) inset;
}

.item-margin{
  margin-bottom: 107px;
}

@media(max-width:1400){
  .secondary-button{
    padding: 14px 40px;
  }
  .item-margin{
    margin-bottom: 128px;
  }
}

@media(max-width:1200px){
  .secondary-button{
    font-size: 14px;
    padding: 14px 35px;
    white-space: nowrap;
  }
  .item-margin{
    margin-bottom: 117px;
  }
}
@media (max-width:768px){
  .secondary-button{
    padding: 10px 30px;
  }
}

@media (max-width: 576px) {

  .item-margin{
    margin-bottom: 60px;
  }
  .secondary-button{
    padding: 14px 51px;
    font-size: 12px;
    margin-left: 5px;
  }
}
