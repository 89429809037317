.banner {
  border-radius: 50px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  color: white;
  font-family: 'Inter' !important;
}

.gradient {
  background: linear-gradient(287.63deg, #4f2376 28.58%, #251038 73.96%);
}

.revert-gradiant {
  background: linear-gradient(287.63deg, #251038 28.58%, #4f2376 73.96%);
}

.pr {
  padding-right: 30px;
}

.pl {
  padding-left: 30px;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.banner-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 50%;
}

.banner-title {
  font-size: 50px;
  font-weight: 400;
  font-family: 'Inter';
}

.banner-colorText {
  background-image: linear-gradient(90deg, #e015a2 29.2%, #624ba2 97.8%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-weight: 800;
}

.banner-details {
  font-size: 24px;
  font-weight: 400;
  font-family: 'Inter';
}

.banner-list {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 50px;
  margin-bottom: 110px;
}

.icon-wrapper {
  display: flex;
  align-items: start;
  gap: 13px;
}

.icon-wrapper img {
  margin-top: 6px;
}

.banner-list-title {
  font-size: 25px;
  font-weight: 400;
  font-family: 'Inter';
  text-align: right;
  /* direction: rtl; */
  text-align: start;
}

.banner-img {
  padding: 24px;
  min-height: 100%;
  min-width: 50%;
  width: 100%;
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
  .banner-title {
    font-size: 30px;
  }

  .banner-details {
    font-size: 20px;
  }

  .banner-list-title {
    font-size: 16px;
  }

  .icon-wrapper img {
    height: 20px;
    width: 20px;
    margin-top: 3px;
  }
}

@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .banner-title {
    font-size: 35px;
  }

  .banner-details {
    font-size: 20px;
  }

  .banner-list-title {
    font-size: 18px;
  }

  .icon-wrapper img {
    height: 20px;
    width: 20px;
    margin-top: 3px;
  }
}

@media screen and (max-width: 576px) {
  .banner-title {
    font-size: 32px;
  }

  .banner-img {
    display: none;
  }

  .banner-details {
    font-size: 18px;
  }

  .banner-list-title {
    font-size: 16px;
  }

  .pl {
    padding-right: 20px;
    padding-left: 20px;
  }

  .pr {
    padding-right: 20px;
    padding-left: 20px;
  }

  .icon-wrapper img {
    margin-top: 0px;
  }

  .banner-list {
    margin-bottom: 40px;
  }
}
