.review-card {
  position: relative;
  width: 370px;
  padding: 33px;
  border-radius: 12px;
  background-color: #0e001a;
  overflow: hidden;
  min-height: 400px;
}

.review-card::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 12px;
  padding: 2px; /* Adjust for thickness of the gradient border */
  background: linear-gradient(180deg, #e015a2 18.6%, #6a56a5 67.44%);
  -webkit-mask: linear-gradient(white, white) content-box,
    linear-gradient(white, white);
  mask: linear-gradient(white, white) content-box, linear-gradient(white, white);
  -webkit-mask-composite: destination-out;
  mask-composite: exclude;
}

.review-card-active {
  background: linear-gradient(
    111.02deg,
    #702cc3 -24.88%,
    #35155d 66.29%
  ) !important;
  position: relative;
  width: 370px;
  padding: 33px;
  border-radius: 12px;
  background-color: #0e001a;
  overflow: hidden;
  min-height: 400px;
}

.review-card-content {
  position: relative;
  z-index: 1; /* Keeps content above the pseudo-element */
  border-radius: 12px; /* Matches the border-radius of the card */
  background-color: #0e001a; /* Background for the content */
}

/* Other styles remain the same */
.review-card-header {
  display: flex;
  align-items: center;
  gap: 12px;
}

.review-card-title {
  font-size: 15px;
  font-family: 'Inter';
  font-weight: 700;
}

.review-card-destination {
  font-size: 13px;
  font-weight: 500;
  font-family: 'Inter';
  color: #aeaeae;
}

.review-card-description {
  font-size: 17px;
  font-weight: 500;
  font-family: 'Inter';
  color: #f5f5f5;
}

@media screen and (max-width: 576px) {
  .review-card {
    width: fit-content !important;
  }

  .review-card-active {
    width: fit-content !important;
  }
}
