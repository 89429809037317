@font-face {
  font-family: satoshi-Bold;
  src: url(../../../satoshi-cufonfonts/Satoshi-Bold.otf);
}

@font-face {
  font-family: satoshi-Light;
  src: url(../../../satoshi-cufonfonts/Satoshi-Light.otf);
}

@font-face {
  font-family: satoshi;
  src: url(../../../satoshi-cufonfonts/Satoshi-Regular.otf);
}
.mobile-menu-container {
  display: none;
}

.galaxy {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1200px !important;
  background-color: transparent !important;
  position: relative;
  overflow: hidden;
  top: -191px;
  z-index: -10;
}

.galaxy .shade {
  position: absolute;

  background-color: #0f001b;
  filter: blur(249px);
  z-index: 0;
}

.galaxy img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 33;
  transition: all 0.5s;
  opacity: 0;
}

.galaxy .content {
  position: absolute;
  top: 100px !important;
  z-index: 222;
  font-family: satoshi-Bold !important;
}

.content p {
  color: #dcdcdcde;
  /* font-size: 10px !important; */
  font-style: normal;
  font-family: satoshi !important;
  line-height: normal;
  margin: 0;
  margin-bottom: 21px !important;
  text-align: center;
  margin-top: 21px;
}

.content div {
  display: flex !important;
  justify-content: center;
  width: 100%;
}

.galaxy h1 {
  color: #fff;
  font-size: 65px;
  font-style: normal;
  font-weight: 700;
  margin-bottom: 1px;
  text-align: center;
}

.tagline_container {
  margin-top: 8px;
  font-family: inter;
  font-weight: 700;
}
.electron {
  border-radius: 100%;
  margin-left: -2px;
  margin-top: -15px;
  height: 80px;
  left: 50%;
  position: absolute;
  top: 0%;
  width: 80px;
  z-index: 222222 !important;
  opacity: 0.5;
  transition: all 0.5s;
}

/* .electron {

  .electron {
    background-color: none !important;
    box-shadow: none !important;
    margin-top: 6.5px;
    margin-left: 5px;
    height: 120px !important;
    width: 120px !important;
    transition: none !important;
    rotate: none !important;
  }
} */

.orbit {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0;
  transition: all 0.5s;
}

.neptune-orbit {
  height: 1308px;
  width: 1308px;
  margin-top: -654px;
  margin-left: -654px;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(170deg) skew(0deg, 0deg);
}

.uranus-orbit {
  height: 1008px;
  width: 1008px;
  margin-top: -504px;
  margin-left: -504px;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(190deg) skew(0deg, 0deg);
}

.jupiter-orbit {
  height: 708px;
  width: 708px;
  margin-top: -354px;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(210deg) skew(0deg, 0deg);
  margin-left: -354px;
}

.earth-orbit {
  height: 408px;
  width: 408px;
  margin-top: -204;
  margin-left: -204;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(230deg) skew(0deg, 0deg);
}

.sun-orbit {
  height: 408px;
  width: 408px;
  margin-top: -204px;
  margin-left: -204px;
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
    rotateY(0deg) rotateZ(260deg) skew(0deg, 0deg);
}
.tagline_container {
  background-image: linear-gradient(152deg, #e015a2 21.52%, #6840d8 94.24%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 65px;
}

.hamburger-container {
  height: 50px;
  display: none !important;
}
.welcome-design {
  font-family: inter;
  color: white;
  font-size: 65px;
  line-height: 1.5rem;
}
@media (max-width: 1400px) {
  .content p {
    margin-top: 8px;
  }
}

@media (max-width: 1200px) {
  .content p {
    margin-top: 5px;
  }
}

@media screen and (max-width: 700px) {
  .content p {
    color: #dcdcdcde;
    font-size: 20px !important;
    font-style: normal;
    font-family: satoshi !important;
    line-height: normal;
    margin: 0;
    margin-bottom: 0px;
    text-align: center;
  }

  .electron {
    .electron {
      background-color: none !important;
      box-shadow: none !important;
      margin-top: 8px;
      height: 70px !important;
      width: 70px !important;
    }
  }
}

@media (min-width: 701px) and (max-width: 1124px) {
  .content p {
    color: #dcdcdcde;
    font-size: 25px !important;
    font-style: normal;
    font-family: satoshi !important;
    line-height: normal;
    margin: 0;
    margin-bottom: 0px;
    text-align: center;
    margin-top: 5px;
  }
  .electron {
    .electron {
      background-color: none !important;
      box-shadow: none !important;
      margin-top: 8px;
      height: 90px !important;
      width: 90px !important;
    }
  }
}

@media (max-width: 576px) {
  .electron {
    .electron {
      background-color: none !important;
      box-shadow: none !important;
      height: 50px !important;
      width: 50px !important;
    }
  }

  .galaxy {
    height: 950px !important;
  }

  .content p {
    color: #dcdcdcde;
    font-size: 12px !important;
    font-style: normal;
    font-family: satoshi !important;
    line-height: normal;
    margin: 0;
    margin-bottom: 17px !important;
    margin-top: 10px !important;
    text-align: center;
  }
  .pinnacle {
    font-size: 12px !important;
    margin-top: 20px !important;
  }

  .subtitle-1 {
    font-size: 10px !important;
    /* color: red !important; */
  }

  .galaxy .content {
    top: 0px !important;
  }

  .welcome-design {
    color: white !important;
    font-size: 32px !important;
    line-height: 2rem;
  }

  .tagline_container {
    background-image: linear-gradient(152deg, #e015a2 21.52%, #6840d8 94.24%);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    font-size: 32px !important;
    text-align: center;
  }

  .octi-learn-logo {
    height: 100px;
  }

  .hamburger-container {
    height: 50px;
    display: none !important;
  }
}

@media (max-width: 768px) {
  .hamburger-container {
    height: 50px;
    cursor: pointer;
    display: block !important;
  }
  .mobile-menu-container {
    display: block;
    width: 99vw;
    height: 100vh;
    position: absolute;
    right: 1px;
    top: 1px;
    flex-direction: column;
    background-color: #0f001b;
    z-index: 9999;
    padding-top: 50px;
    overflow-y: scroll;
  }
  .mobile-menu-top-p {
    font-family: Satoshi;
    font-weight: 400;
    font-size: 12px;
    text-align: center;
    width: 90%;
    color: #b8bbca;
    margin-top: 10px;
  }
  .octi-name {
    color: #bd23a2;
  }
  .mobile-menu-main {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .mobile-menu-top {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .menu-hr {
    width: 80%;
    margin-top: 30px;
    margin-bottom: 30px;
    opacity: 1;
  }

  .navigation_menu-mobile {
    display: flex;
    flex-direction: column;
    color: #e0dadad7;
    list-style: none;
    cursor: pointer;
    gap: 50px;
    width: 100%;
    text-align: center;
    padding: 0px !important;
  }

  .navigation_menu-mobile ul li {
    text-align: justify;
    display: flex;
    list-style: none;
    font-family: 'Inter';
    transition: all 0.5s;
    cursor: pointer;
  }

  .navigation_menu-mobile ul li:hover {
    color: #6a56a5;
  }
  .exit-btn-div {
    position: absolute;
    right: 30px;
    cursor: pointer;
  }
  .mobile-menu-footer-main {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: 80px;
  }
  .mobile-menu-footer-icon {
    background-color: rgba(98, 75, 162, 0.2);
    width: 90%;
    height: 100px;
    border-radius: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .mobile-menu-footer-icon > p {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    color: #e015a2;
  }
  .mobile-menu-wait {
    margin-top: 10px;
    width: 80%;
    text-align: center;
    color: rgba(184, 187, 202, 1);
    font-size: 16px;
    font-weight: 400;
  }
}
