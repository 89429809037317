.join-us-button {
  margin-top: 51px;
}

.gradient_button {
  background: linear-gradient(90.06deg, #e015a2 -10.53%, #2f234f 100.54%);
  color: white;
  padding: 14px 0px;
  border-radius: 50px;
  min-width: 150px !important;
  padding: 14px 30px;
  border: none !important;
  outline: none !important;
}

.gradient_button:hover {
  background: linear-gradient(90.06deg, #2f234f -8.45%, #e015a2 101.47%);
  transition: 300ms;
}
